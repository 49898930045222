import React, { useEffect } from "react";
import "./css/Contact.css";

function Contact() {
  useEffect(() => {
    const inputs = document.querySelectorAll(".input");

    function focusFunc() {
      let parent = this.parentNode;
      parent.classList.add("focus");
    }

    function blurFunc() {
      let parent = this.parentNode;
      if (this.value === "") {
        parent.classList.remove("focus");
      }
    }

    inputs.forEach((input) => {
      input.addEventListener("focus", focusFunc);
      input.addEventListener("blur", blurFunc);
    });
  }, []);
  return (
    <div id ="contact-section"class="contact-container">
      <span class="big-circle"></span>
      <div class="form">
        <div class="contact-info">
          <h3 class="title">Let's get in touch</h3>
          <p class="text">
            At Broomsville Surveillance, we're committed to your security. Our
            expert team delivers tailored solutions for your protection. Contact
            us to learn more!
          </p>

          <div class="info">
            <div class="information">
              <img src="./images/location.png" class="icon" alt="" />
              <p>Nairobi, Kenya</p>
            </div>
            <div class="information">
              <img src="./images/email.png" class="icon" alt="" />
              <p>contact@broomsvillesurveillance.co.ke</p>
            </div>
            <div class="information">
              <img src="./images/phone.png" class="icon" alt="" />
              <p>+254-722-311-294</p>
            </div>
          </div>

          <div class="social-media">
            <p>Connect with us :</p>
            <div class="social-icons">
              <a href="#">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="https://www.tiktok.com/@broomsville.surve">
                <i class="fab fa-tiktok"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="contact-form">
          <span class="circle one"></span>
          <span class="circle two"></span>

          <form action="index.html" autocomplete="off">
            <h3 class="title">Contact us</h3>
            <div class="input-container">
              <input type="text" name="name" class="input" />
              <label for="">Username</label>
              <span>Username</span>
            </div>
            <div class="input-container">
              <input type="email" name="email" class="input" />
              <label for="">Email</label>
              <span>Email</span>
            </div>
            <div class="input-container">
              <input type="tel" name="phone" class="input" />
              <label for="">Phone</label>
              <span>Phone</span>
            </div>
            <div class="input-container textarea">
              <textarea name="message" class="input"></textarea>
              <label for="">Message</label>
              <span>Message</span>
            </div>
            <input type="submit" value="Send" class="btn" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
